/*global _, $, jQuery, MatchHeightHelper*/
import Helper from './services/Helper/Helper.js';
import Select from './widgets/Select/Select.js';

(function($, MatchHeightHelper) {
  $(function() {
    var
      $body = $('body'),
      cookieAcceptLinkText = $body.data('cookiesLinkAcceptText'),
      cookieLinkText = $body.data('cookiesLinkText'),
      cookieMessage = $body.data('cookiesMessage'),
      cookiePolicyUrl = $body.data('cookiesPolicyUrl'),
      cookiePosition = 'bottom' === $body.data('cookiesPosition'),
      country = $body.data('country') ? $body.data('country'): 'fr'
    ;

    $.cookieCuttr({
      cookieAnalytics: false,
      cookieMessage: cookieMessage + ('fr' === country ? '&nbsp;<a class="cc-cookie-more" target="_blank" href="{{cookiePolicyLink}}">' + cookieLinkText + '</a>': ''),
      cookieAcceptButtonText: cookieAcceptLinkText,
      cookiePolicyLink: cookiePolicyUrl,
      cookieNotificationLocationBottom: cookiePosition
    });

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    // Init Fancybox
    require('fancybox/dist/js/jquery.fancybox.cjs.js')($);
    $('.fancybox').fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('function' === typeof $.fn.validate) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        highlight(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        errorElement: 'span',
        errorClass: 'help-block',
        errorPlacement(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
        $(e).validate({
          rules: {
            'front_message_type[email]': {
              cemail: true,
              email: false,
            },
            'front_newsletter_type[email]': {
              cemail: true,
              email: false,
            },
          },
        });
      });
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      MatchHeightHelper.update(
        event.buckle.getContainer()[0],
        true,
        '.js-match-height .card'
      );
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      // Fetch content of the folder.
      $(event.target).closest('.js-buckle--folder').data('buckle').fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var $element = event.buckle.getElement();

      // Update the grid.
      MatchHeightHelper.update(
        event.buckle.getContainer()[0],
        true,
        '.js-match-height .card'
      );

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      autoclose: true,
      fontAwesome: true,
      format: 'yyyy-mm-dd',
      minView: 'month',
      startView: 'year',
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    Helper
      .convertToArray(document.querySelectorAll('select'))
      .map((select) => new Select(select))
    ;
  });
}(jQuery, MatchHeightHelper));
